// Bootstrap
@import '../../../node_modules/bootstrap/scss/functions';

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #262626 !default;
$black: #000 !default;

$body-bg: #f2f3f5 !default;

/*
  custom colors gray shades ( brand, light , dark ) according to the style guide, please check:
  https://projects.invisionapp.com/share/4UET059JX#/screens/275251242
*/

$brand-red-light: #e2000f;
$brand-red-dark: #b6000f;
$brand-gray: #869098;

$gray-light-1: #9da7af;
$gray-light-2: #b4bcc3;
$gray-light-3: #ccd3d8;
$gray-light-4: #e5e8eb;
$gray-light-5: #f1f3f5;

$gray-dark-1: #575756;
$gray-dark-2: #878786;
$gray-dark-3: #b0b0b1;
$gray-dark-4: #d8d8d9;
$gray-dark-5: #ebebeb;

$success: #1ac632;
$warning: #fe8a02;
$danger: #e2000f;

$primary: $brand-gray;
$secondary: $white;

$custom-colors: (
  'landing': $brand-red-light,
);

$text-muted: $gray-light-3;

// font size and family overrides
$font-family-base: 'Roboto', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.25rem;
$h4-font-size: 1.125rem;
$small-font-size: 0.875rem;

// Pagination links should retain brand-primary link color
$pagination-color: $primary;
$pagination-hover-color: darken($pagination-color, 15%);

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$border-color: $gray-light-3;

$breadcrumb-bg: none;
$breadcrumb-divider: '›';
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;

$input-bg: $white;
$input-placeholder-color: $gray-500;
$input-border-radius: 0.125rem;

$btn-border-radius: 0.25rem;
$btn-border-radius-lg: 0.25rem;
$btn-font-weight: 700;

$dropdown-border-radius: 0.25rem;
$dropdown-link-hover-bg: $gray-light-1;
$dropdown-link-active-bg: $gray-light-1;
$dropdown-link-hover-color: $white;

$nav-tabs-link-active-bg: $white;
$nav-tabs-border-width: 0;

$navbar-brand-font-size: 1.5rem;
$navbar-padding-x: 1.5rem;
$navbar-dark-color: $white;
$navbar-brand-padding-y: 0;

$table-active-bg: $gray-light-5;
$table-border-color: $gray-light-3;

$form-check-input-checked-bg-color: #1bc632;
$form-check-input-border-radius: 0.25rem;

$card-cap-bg: $white;

$link-color: $brand-red-light;

$lead-font-size: 1.5rem;
$lead-font-weight: normal;

$progress-bg: $gray-400;

$table-bg: transparent;
$card-bg: $white;
$modal-content-bg: $white;

$fonts-path: '.' !default;

@import '../../../node_modules/bootstrap/scss/variables';

$theme-colors: map-merge($theme-colors, $custom-colors);
$enable-dark-mode: false;

@import '../../../node_modules/bootstrap/scss/maps';
@import '../../../node_modules/bootstrap/scss/mixins';
