@import 'variables';

.btn-primary {
  border-color: $brand-gray;
  background-color: $brand-gray;
  color: $white;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);

  &.active,
  &:active,
  &.hover,
  &:hover {
    border-color: $gray-light-2;
    background-color: $gray-light-2;
  }

  &.disabled,
  &:disabled {
    border-color: $gray-light-3;
    background-color: $brand-gray;
    color: $white;
  }
}

.btn-outline-white {
  @include button-outline-variant(white, $brand-red-light);
  border-width: 2px;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $brand-red-light;
  }
}

.pagination {
  --bs-pagination-focus-color: var(--bs-black);
}

.btn-close {
  background-size: 12.8px 12.8px;
}
