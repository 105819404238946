@import 'variables';

.navbar {
  --bs-navbar-brand-margin-end: 1.5rem;
}

.nav-tabs {
  border-bottom: 1px $gray-light-4 solid;

  .nav-link {
    color: $gray-light-3;
    padding: 1rem;
  }

  .nav-item {
    margin-bottom: -1px;
  }

  .nav-link:hover {
    border-bottom: 2px $gray-light-1 solid;
  }

  .nav-link.active {
    color: $gray-light-1;
    border-bottom: 2px $brand-red-dark solid;
  }
}
