@import 'variables';

@font-face {
  font-family: 'Roboto';
  src:
    url($fonts-path + '/fonts/Roboto-Regular.woff2') format('woff2'),
    url($fonts-path + '/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url($fonts-path + '/fonts/Roboto-Medium.woff2') format('woff2'),
    url($fonts-path + '/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 700;
}
