@import 'variables';

label {
  font-size: $font-size-sm;
  color: $gray-dark-1;
  margin-bottom: 0.375rem;
}

.form-select:disabled {
  color: $gray-500;
}
