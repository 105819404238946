@import 'variables';

.table {
  border-bottom-width: 0px;

  thead th {
    font-weight: bold;
    text-transform: uppercase;
    border: none !important;
    color: $gray-dark-2;
    font-size: 0.875rem;
  }
  // TODO: This is a horrible approach. Find a better one
  tr .btn-link {
    padding: 0 0 0 0.75rem;
    line-height: 1;

    &:first-child {
      padding: 0;
    }
  }

  > :not(caption) > * > * {
    border-top: 1px solid $gray-light-3;
    border-bottom-width: 0;
    padding: 0.75rem;
  }
}

.table-no-rows-border td,
.table-no-rows-border th {
  border: none;
}
